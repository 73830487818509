import window from 'global';
import * as React from 'react';
import Footer from './Footer';
import Head from './Head';
import MobileNavbar from './MobileNav';
import Navbar from './NavBar';

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`;
  const isRootPath = location.pathname === rootPath;
  const breakpoint = 991;
  const [dimension, setDimension] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Head />
      {dimension.width > breakpoint ? <Navbar /> : <MobileNavbar />}
      <div className="global-wrapper" data-is-root-path={isRootPath} style={{ top: '30%' }}>
        <main>{children}</main>
      </div>
      <Footer />
    </>
  );
};

export default Layout;
